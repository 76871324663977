<template>
    <div>
        <action-button
            :icon="type === 'cancel' ? 'trash' : 'outbound-link'"
            @click="$emit('callback')">
            {{ type === 'cancel' ? 'CANCEL' : 'CONTINUE' }}
        </action-button>
    </div>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';

export default {
    components: {
        ActionButton
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: ''
        }
    }
};
</script>